<template>
  <!-- 消息通知 -->
  <div class="Notification" v-loading="loading">
    <div class="content">
      <!-- 具体内容 -->
      <div class="contentds">
        <!-- 资源交易 -->
        <div class="assembly">
          <div class="ZYcommt">
            <!-- 资源审核 -->
            <div class="toped" v-if="
              this.silit.length!=0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">资源审核</span>
            </div>
            <el-timeline>
              <el-timeline-item
                v-for="item in silit"
                :key="item.id"
                color="#0084FF"
                class="tttop ZYJY"
              >
                <div>
                  <span class="just">
                    你上传的
                    <!-- 于{{ item.creatime }} -->
                    <span style="color:blue">
                      {{
                      item.name
                      }}
                    </span>
                    <span v-if="item.state==2">审核通过了</span>
                    <span v-else>审核未通过</span>
                  </span>
                </div>
                <div>
                  <span class="justW">{{ item.creatime }}</span>
                  <!-- <span @click="deletes(item.id)" class="justW">删除</span> -->
                </div>
              </el-timeline-item>
            </el-timeline>
            <!-- <div class="tttop" v-for="item in silitn" :key="item.id">
              <span class="just">
                你于{{ item.creatime }}上传的
                <span style="color:blue">
                  {{
                  item.name
                  }}
                </span>未通过审核
              </span>
            </div>-->
            <!-- 购买的资源 -->
            <div class="toped" v-if="
              this.billlist.length!=0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">购买的资源</span>
            </div>
            <el-timeline>
              <el-timeline-item
                v-for="item in billlist"
                :key="item.id"
                color="#0084FF"
                class="tttop ZYJY"
              >
                <div>
                  <span class="just">
                    你花费
                    <!-- 于{{ item.creatime }} -->
                    <span style="color:red">{{ item.Price }}知识币</span>购买了
                    <span style="color:blue">{{ item.name }}</span>
                  </span>
                </div>
                <div>
                  <span class="justW">{{ item.creatime }}</span>
                  <!-- <span @click="deletes(item.id)" class="justW">删除</span> -->
                </div>
              </el-timeline-item>
            </el-timeline>
            <!-- 资源被购买 -->
            <div class="toped" v-if="
              this.billlista.length!=0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">资源被购买</span>
            </div>
            <el-timeline>
              <el-timeline-item
                v-for="item in billlista"
                :key="item.id"
                color="#0084FF"
                class="tttop ZYJY"
              >
                <div>
                  <span class="just">
                    {{ item.username }}花费
                    <!-- 于{{ item.creatime }} -->

                    <span style="color:red">{{ item.Price }}知识币</span>购买了你的
                    <span style="color:blue">{{ item.name }}</span>
                  </span>
                </div>
                <div>
                  <span class="justW">{{ item.creatime }}</span>
                  <!-- <span @click="deletes(item.id)" class="justW">删除</span> -->
                </div>
              </el-timeline-item>
            </el-timeline>
            <!-- ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| -->
            <!-- 订阅他人 -->
            <div class="toped" v-if="
              this.bill_subs_other.length!=0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">订阅他人</span>
            </div>
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in bill_subs_other"
                :key="index"
                color="#0084FF"
                class="tttop ZYJY"
              >
                <div>
                  <span class="just">
                    <!-- 于 {{ item.creatime }}  -->
                    你花费
                    <span style="color:red">{{ item.Price }}知识币</span> 订阅了
                    <span style="color:blue">{{ item.name }}</span>
                  </span>
                </div>
                <div>
                  <span class="justW">{{ item.creatime }}</span>
                  <!-- <span @click="deletes(item.id)" class="justW">删除</span> -->
                </div>
              </el-timeline-item>
            </el-timeline>

            <!-- 被他人订阅 -->
            <div class="toped" v-if="
              this.bill_subsed_other.length!=0
            ">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">被他人订阅</span>
            </div>

            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in bill_subsed_other"
                :key="index"
                color="#0084FF"
                class="tttop ZYJY"
              >
                <div>
                  <span class="just">
                    {{ item.username }}花费
                    <!-- 于 {{ item.creatime }}  -->

                    <span style="color:red">{{ item.Price }}知识币</span>
                    订阅了你
                    <span style="color:blue"></span>
                  </span>
                </div>
                <div>
                  <span class="justW">{{ item.creatime }}</span>
                  <!-- <span @click="deletes(item.id)" class="justW">删除</span> -->
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <V-Loading></V-Loading>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "@/views/wisdom-answer/index/team-response/Pop/Loading/index.vue";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import {
  updateNews,
  formatDate,
  AcceptNum,
  AcceptNumTeam,
  showTimeFormat
} from "@/js/common/index.js";
import {
  formatZero,
  getTimeFormat,
  getTimeFormat_1
} from "@/js/common/Resoures";

export default {
  data() {
    return {
      son: [],
      loading: false, //是否显示正在加载中
      teamTypes: "",
      visable: false,
      classification: -1,
      silit: [],
      silitn: [],
      billlist: [],
      billlista: [],
      userid: null, //用户id
      bill_subs_other: [], // 订阅他人
      bill_subsed_other: [] // 被他人订阅
    };
  },
  created() {
    //alert(this.$store.state.userinfo.id)
    this.userid = this.$store.state.userinfo.id;
    this.init();
    // this.timer();
  },
  components: {
    "V-Loading": Loading //正在加载中
  },
  methods: {
    // 初次加载
    async init() {
      if (this.userid != undefined) {
        /////修改方式为已读
        let updateNews = await UPDATE(
          "post",
          "",
          "update_MessageNotification(where: {userid: {_eq: " +
            this.$store.state.userinfo.id +
            "}}, _set: {identification : 1}) { affected_rows  }  "
        );
        if (updateNews.data.update_MessageNotification.affected_rows > 0) {
          console.log("修改为已读成功！");
        }
        this.sclitall(); //审核通知
        this.billall(); //收支消息
        this.subscribeNotice(); // 订阅他人 被他人订阅消息通知
      }
    },

    //    删除消息
    async deletes(id) {
      this.loading = true; //显示
      let data = await DELETE(
        "POST",
        "",
        " delete_MessageNotification(where: {id: {_eq: " +
          id +
          "}}) {  affected_rows  }"
      ); ///删除消息通知
      if (data.data.delete_MessageNotification.affected_rows == 1) {
        this.$message({
          type: "success",
          message: "删除成功！"
        });
        // 刷新页面
        this.init();
        this.loading = false; //显示
      }
    },
    //审核
    async sclitall() {
      let info = await QUERYED(
        "POST",
        "",
        "   ZYDocument_ALL(where: {user: {_eq: " +
          this.userid +
          "}}) {id  name   Time  state}"
      );
      this.silit = [];
      for (let i = 0; i < info.data.ZYDocument_ALL.length; i++) {
        // switch (info.data.ZYDocument_ALL[i].state) {
        //   case "2":
        this.silit.push({
          id: info.data.ZYDocument_ALL[i].id,
          creatime: showTimeFormat(
            info.data.ZYDocument_ALL[i].Time
          ),
          name: info.data.ZYDocument_ALL[i].name,
          state: info.data.ZYDocument_ALL[i].state
        });
        //     break;
        //   case "3":
        //     this.silitn.push({
        //       id: info.data.ZYDocument_ALL[i].id,
        //       creatime: getTimeFormat_1(
        //         Date.parse(info.data.ZYDocument_ALL[i].Time)
        //       ),
        //       name: info.data.ZYDocument_ALL[i].name
        //     });
        //     break;
        // }
      }
    },
    ///购买的资源
    async billall() {
      let info = await QUERYED(
        "POST",
        "",
        "   ALLBill(where: {userid: {_eq: " +
          this.userid +
          "},state: {_eq: " +
          0 +
          "},type: {_eq: " +
          2 +
          "}}) {id  ordername   time  state Price}"
      );
      this.billlist = [];
      for (let i = 0; i < info.data.ALLBill.length; i++) {
        this.billlist.push({
          id: info.data.ALLBill[i].id,
          Price: info.data.ALLBill[i].Price,
          creatime: showTimeFormat(info.data.ALLBill[i].time),
          name: info.data.ALLBill[i].ordername
        });
      }
      let infoa = await QUERYED(
        "POST",
        "",
        "   ALLBill(where: {rsuser: {_eq: " +
          this.userid +
          "},state: {_eq: " +
          0 +
          "}}) {id  ordername   time  state Price userid}"
      );
      this.billlista = [];
      for (let i = 0; i < infoa.data.ALLBill.length; i++) {
        let infoaa = await QUERYED(
          "POST",
          "",
          "   ZYUser(where: {id: {_eq: " +
            infoa.data.ALLBill[i].userid +
            "}}) {username }"
        );
        console.log("infoaa.data.ZYUser", infoaa.data.ZYUser);
        // alert(infoaa.data.ZYUser[0].username)
        if (infoaa.data.ZYUser.length != 0) {
          this.billlista.push({
            username: infoaa.data.ZYUser[0].username,
            id: infoa.data.ALLBill[i].id,
            Price: info.data.ALLBill[i].Price,
            creatime: showTimeFormat(infoa.data.ALLBill[i].time),
            name: infoa.data.ALLBill[i].ordername
          });
        }
      }
    },
    /**
     * 订阅 消息通知 过期未继续订阅则视为取消对该用户的订阅
     */
    async subscribeNotice() {
      // 我订阅他人时
      let resp = await QUERYED(
        "post",
        "",
        "SubscribeUserInfo(where:{subscribers_user_id:{_eq:" +
          this.$store.state.userinfo.id +
          "},subscribe_state:{_eq:" +
          0 +
          "}}) {id  subscribe_type createTime subscribed_user_name}"
      );
      console.log("订他: ", resp);

      let temp = resp.data.SubscribeUserInfo;
      if (temp.length > 0) {
        for (let j = 0; j < temp.length; j++) {
          if (temp[j].subscribe_type.search("基本") > -1) {
            temp[j].subscribe_type = temp[j].subscribe_type
              .split("基本费用 (")[1]
              .split(") +")[0];
          } else {
            temp[j].subscribe_type = temp[j].subscribe_type
              .split("一次性付费 (")[1]
              .split(") +")[0];
          }
          this.bill_subs_other.push({
            creatime: showTimeFormat(temp[j].createTime),
            Price: temp[j].subscribe_type,
            name: temp[j].subscribed_user_name,
            id: temp[j].id
          });
        }
      }

      // 他人订阅我时
      let resp2 = await QUERYED(
        "post",
        "",
        "SubscribeUserInfo(where:{subscribed_user_id:{_eq:" +
          this.$store.state.userinfo.id +
          "},subscribe_state:{_eq:" +
          0 +
          "}}) { id subscribe_type createTime subscribers_user_name}"
      );
      console.log("订他resp2: ", resp2);
      let temp2 = resp2.data.SubscribeUserInfo;
      if (temp2.length > 0) {
        for (let j = 0; j < temp2.length; j++) {
          console.log(
            'temp2[j].subscribe_type.search("基本"): ',
            temp2[j].subscribe_type.search("基本")
          );
          if (temp2[j].subscribe_type.search("基本") > -1) {
            temp2[j].subscribe_type = temp2[j].subscribe_type
              .split("基本费用 (")[1]
              .split(") +")[0];
          } else {
            temp2[j].subscribe_type = temp2[j].subscribe_type
              .split("一次性付费 (")[1]
              .split(") +")[0];
          }
          this.bill_subsed_other.push({
            creatime: showTimeFormat(temp2[j].createTime),
            Price: temp2[j].subscribe_type,
            username: temp2[j].subscribers_user_name,
            id: temp2[j].id
          });
        }
      }
    }
  }
};
</script>
<style scoped>
.content_top {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid rgba(125, 125, 125, 1);
}
.size {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
}
.size-right {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(103, 103, 103, 1);
  float: right;
  cursor: pointer;
  display: inline-block;
}
.content_center {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  /* line-height: 50px; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgb(83, 83, 83);
}
.img {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-image: url("~@/assets/bigLOGO.png");
  position: relative;
  top: 3px;
  left: -5px;
}
.demo1 {
  width: 100px;
  background: white;
  /* border:1px solid rgba(124,124,124,1); */
  border-radius: 5px;
  display: inline-block;
  position: relative;
  top: -10px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(87, 87, 87, 0.44);
}
.demo1 > span {
  width: 100%;
  /* border: 1px solid red; */
  line-height: 30px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

/*气泡框效果实现*/

.demo {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: white;
  /* border:1px solid rgba(124,124,124,1); */
  transform: rotate(50deg);
  position: relative;
  left: -40px;
  top: 5px;
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(87, 87, 87, 0.44);
}
.right_rigth {
  width: 100%;
  text-align: right;
  display: inline-block;
  /* border: 1px solid red; */
}
.wid {
  width: 100%;
  text-align: right;
  display: inline-block;
}
.imges {
  background-image: url("~@/assets/icon/down1.png");
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  position: relative;
  top: 10px;
}
.demo1 > span:hover {
  color: #0084ff;
}
.classification {
  color: #0084ff;
  /* font-weight: bold; */
  /* cursor:pointer */
}
.blue {
  color: #0084ff;
  cursor: pointer;
  text-decoration: underline;
}
/* 组件 */
.contentds {
  width: 100%;
  /* border: 1px solid rebeccapurple; */
}
.assembly {
  width: 100%;
  /* border: 1px solid darkgoldenrod; */
}
.assembly_top {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
}

.ZYJY {
  width: 100%;
  /* height: 30px; */
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
/* .TTcommt>div :hover,.TTCollection>div :hover,.ZNCollection>div :hover,.ZNcommt>div :hover,.ZNinvitation>div :hover,.ZNnotice>div :hover,.ZYcommt>div :hover,.ZYCollection>div :hover{
    background-color: bisque;
    display: inline-block;
} */
.tttop:hover {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 5px;
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(219, 219, 219, 0.44);
}
.name {
  font-weight: bold;
  margin-right: 10px;
  /* margin-left: 10px; */
}
.just {
  /* margin-left: 10px; */
  color: #000000;
}
.td {
  margin-right: 10px;
}
.tu {
  width: 3px;
  height: 3px;
  background: rgba(0, 132, 255, 1);
  border-radius: 80%;
  display: inline-block;
  margin-right: 3px;
}
.cha {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/fork.png");
  position: relative;
  top: 8px;
  left: 5px;
  cursor: pointer;
}
.tops {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0084ff;
  margin-top: 10px;
}
.toped {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* border: 1px solid red; */
  height: 40px;
}
.people {
  width: 95%;
  border: 1px solid #d6d6d6;
  padding: 1%;
  margin: auto;
  border-radius: 5px;
}
.feed {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  /* 超出范围后换行 */
  white-space: pre-wrap;
}
::v-deep .el-timeline-item__tail {
  border-left: 1px dashed #a6beec;
}
::v-deep .el-timeline-item {
  padding-bottom: 1px;
}
.justW {
  /* margin-left: 10px; */
  color: #006cff;
  margin-right: 100px;
}
.postion {
  position: relative;
  top: -8px;
  margin-left: 5px;
}
</style>
